export var legalModeIntValue = -32768;
export var hiddenPhotoStub =
    "iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAIAAAD/gAIDAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAALCSURBVHhe7dTBcRshAEZhFeKjq3BBqsfVuBnXkBqSBQELT5NfIgdPnLw3e1ixCNjPHl1+vLx6PXmJtXGJtXGJtXGJtXER66dNAUesFHDESgFHrBRwxEoBR6wUcMRKAUesFHDESgFHrBRwxEoBR6wUcMRKAUesFHDESgFHrBRwxEoBR6wUcMRKAUesFHDESgFHrBRwxEoBR6wUcMRKAUesFHDESgFHrBRwxEoBR6wUcMRKAUesFHDESgFHrBRwxEoBR6wUcMRKAUesFHDESgFHrBRwnsX6uF4u14/24b8JOI+xCtPc2/tne/LvB5yM9fn+1nTGf1a1G/9jdULrNjiP1Bru/cy16Xl9XD73eceet1WOweNm/PnG322e3GtfmvetPXmeGnAi1nSGgTXd1v36RsuHZf5Rmlkr0/urf1z7S94mlbvlDecP5xSsWJbsKx5tnqcFnIg17TDd9pPxhMvplsPFmaVl9q3xnfkZFhrrlPFRW3rdZNnh4Xl6wPkDrH677H+0HGF5GGeWjgk87fn+00x8caw7jdfvlft1zb3z9ICz+5t1jtxtsZxuOU+cWeLpj8Z3yk2fjYXGOvN4n79usneeHnAy1lFZeOncpT7iGVvr66eZtTK9j/E3a1qrrtMnTutMk8foKrB5nhZwHmK11s1GZbi17nc3/7czW/X80+Pp/c/XuQ2OpcYG55eP2ugxa9po+zw14DyL9Xc0C35FwBErBRyxUsD5XlhfHXDESgFHrBRwxEoBR6wUcMRKAUesFHDESgFHrBRwxEoBR6wUcMRKAUesFHDESgFHrBRwxEoBR6wUcMRKAUesFHDESgFHrBRwxEoBR6wUcMRKAUesFHDESgFHrBRwxEoBR6wUcMRKAUesFHDESgFHrBRwxEoBR6wUcMRKAUesFHDESgGHWF7hEmvjEmvjEmvjEuvp6+X1F4Q/+lPfSXFFAAAAAElFTkSuQmCC";
export var UserRoles = {
    admin: "Администратор системы (полный доступ ко всем компонентам)",
    editor: "Редактор (может редактировть запись любого пользователя даже после публикации)",
    editor_local: "Локальный редактор (может редактировать свои записи даже после публикации)",
    writer: "Права на внесение новых записей от своего имени",
    eval: "Доступ к конечной точке с бизнес-правилами",
    flag_real_data: "Права на установку флага «Подлинные данные»",
    logist_paid: "Роль с платным доступом к сервису логистов",
    logist_free: "Роль с бесплатным доступом к сервису логистов",
    legal_mode: "Включение LEGAL MODE",
    manager: "Менеджер",
    passport_to_face_verification: "Доступ к верификации соответствия паспортных данных и лица",
    driver_license_to_face_verification: "Доступ к верификации соответствия водительского удостоверения и лица",
    individual_to_face_verification: "Доступ к верификации соответствия Ф.И.О. + д.р. и лица",
    driver_license_relevance_verification: "Доступ к проверке действительности ВУ",
    passport_relevance_verification: "Доступ к проверке действительности паспорта"
};
export class Member {
    public id: string;
    public humanId: Number | null;
    constructor(id: string, humanId: number | null) {
        this.id = id;
        this.humanId = humanId;
    }
}
export class PhotoBound {
    public x1: number | null = null;
    public y1: number | null = null;
    public x2: number | null = null;
    public y2: number | null = null;
    public rotate: string | null = null;
    public faceId: string | null = null;
}
export class RecordFlags {
    public isFraud: boolean | null = null;
    public isBankFraud: boolean | null = null;
    public isMicrofinanceFraud: boolean | null = null;
    public isInsuranceFraud: boolean | null = null;
    public isMoneyLaundering: boolean | null = null;
    public isCargoThief: boolean | null = null;
    public isShopThief: boolean | null = null;
    public isEmployRisk: boolean | null = null;
    public isRealData: boolean | null = null;
    public isCompromised: boolean | null = null;
}
export class RecordContacts {
    public phones: string[] = [];
    public emails: string[] = [];
}
export class SimilarSearchResult {
    public text: FeedBatch | null = null;
    public face: any = null;
    public counts: any = null;
}
export class UserBalanceRefillRequest {
    public amount: number | null = null;
    public email: string | null = null;
    public phone: string | null = null;
}
export class UserBalanceRefillResponse {
    public redirectUri: string | null = null;
}
export class UserLogRecord {
    public code: string | null = null;
    public context: Array<string> | null = null;
    public timestamp: Date | null = null;
}
export class UserLogBatch {
    public records: Array<UserLogRecord> | null = null;
    public session: string | null = null;
    public total: number | null = null;
}
export class FileDetectResult {
    public type: string | null = null;
}
export class UserSession {
    public user: User | null = null;
    public secret: string | null = null;
}
export class RecordFields {
    public firstname: string | null = null;
    public lastname: string | null = null;
    public patronymic: string | null = null;
    public birthdayDay: number | null = null;
    public birthdayMonth: number | null = null;
    public birthdayYear: number | null = null;
    public passportSerial: string | null = null;
    public passportNumber: string | null = null;
    public passportIssueYear: number | null = null;
    public passportIssueMonth: number | null = null;
    public passportIssueDay: number | null = null;
    public inn: string | null = null;
    public get birthday(): Date | null {
        if (this.birthdayDay === null) {
            return null;
        }
        if (this.birthdayMonth === null) {
            return null;
        }
        if (this.birthdayYear === null) {
            return null;
        }
        const date = new Date();
        date.setUTCFullYear(this.birthdayYear, this.birthdayMonth - 1, this.birthdayDay);
        return date;
    }
    public set birthday(data: Date | null) {
        if (data === null) {
            this.birthdayDay = null;
            this.birthdayMonth = null;
            this.birthdayYear = null;
        } else {
            this.birthdayDay = data.getDate();
            this.birthdayMonth = data.getMonth() + 1;
            this.birthdayYear = data.getFullYear();
        }
    }
    public get passportIssue(): Date | null {
        if (this.passportIssueDay === null) {
            return null;
        }
        if (this.passportIssueMonth === null) {
            return null;
        }
        if (this.passportIssueYear === null) {
            return null;
        }
        const date = new Date();
        date.setUTCFullYear(this.passportIssueYear, this.passportIssueMonth - 1, this.passportIssueDay);
        return date;
    }
    public set passportIssue(data: Date | null) {
        if (data === null) {
            this.passportIssueDay = null;
            this.passportIssueMonth = null;
            this.passportIssueYear = null;
        } else {
            this.passportIssueDay = data.getDate();
            this.passportIssueMonth = data.getMonth() + 1;
            this.passportIssueYear = data.getFullYear();
        }
    }
}
export class RecognizedDocument {
    public type: string | null = null;
    public fields: object | null = null;
}
export class Location {
    public lat: number | null = null;
    public lng: number | null = null;
    public geoHash: string | null = null;
}
export class RecordPerson {
    public personId: string | null = null;
    public importId: string | null = null;
    public authorId: string | null = null;
    public humanId: number | null = null;
    public status: RequestStatus | null = null;
    public flags: RecordFlags = new RecordFlags();
    public fields: RecordFields = new RecordFields();
    public contacts: RecordContacts = new RecordContacts();
    public dateTime: Date | null = null;
    public timestamp: Date | null = null;
    public location: Location | null = null;
    public loss: number | null = null;
    public fable: string | null = null;
    public comment: string | null = null;
    public photos: Array<string> = [];
    public files: Array<string> = [];
}
export class Record {
    public recordId: string | null = null;
    public importId: string | null = null;
    public authorId: string | null = null;
    public humanId: number | null = null;
    public dateTime: Date | null = null;
    public timestamp: Date | null = null;
    public status: RequestStatus | null = null;
    public persons: Array<string> = new Array<string>();
    public companies: Array<string> = new Array<string>();
    public comment: string | null = null;
    public flags: RecordFlags | null = null;
    public photos: Array<string> = new Array<string>();
    public files: Array<string> = new Array<string>();
    public location: Location | null = null;
    public loss: number | null = null;
}
export class FaceCompareResult {
    public isTheSame: boolean | null = null;
    public similarity: Array<Array<number>> | null = null;
    public strictLevel: number | null = null;
}
export class RecordPhotoContext {
    public id: string | null = null;
    public name: string | null = null;
    public content: Blob | string | null = null;
    public isDeleted: boolean = false;
}
export class RecordPersonContext {
    public person: RecordPerson | null = null;
    public photos: Array<RecordPhotoContext> = [];
    public files: Array<RecordPhotoContext> = [];
    public isHidden: boolean | null = null;
}
export class UserLogDate {
    public year: number | null = null;
    public month: number | null = null;
    public day: number | null = null;
}
export class RequestContext {
    public stage: number = 0;
    public isInProcess: boolean = false;
    public isInFailure: boolean = false;
    public failureCode: string | null = null;
    public record: Record | null = null;
    public persons: Array<RecordPersonContext> | null = null;
    public dropped: Array<RecordPersonContext> | null = new Array();
    public current: RecordPersonContext | null = null;
    public photos: Array<RecordPhotoContext> = [];
    public files: Array<RecordPhotoContext> = [];
    public resolvedLocation: ResolvedCoordinates = null;
    public similar: any = {};
    public similarFilter: string | null = null;
    public similarCounters: any = null;
    public isInSimilarLoading: boolean = false;
    public moveNext(): void {
        this.stage = this.stage + 1;
    }
    public moveBack(): void {
        this.stage = this.stage - 1;
    }
}
export abstract class LoadPhotoRequest {
    searchId: string | null;
    photoId: string;
    base64: string | null = null;
    constructor(photoId: string, searchId: string | null = null) {
        this.searchId = searchId;
        this.photoId = photoId;
    }
}
export class LoadRecordPhotoRequest extends LoadPhotoRequest {
    recordId: string;
    constructor(recordId: string, photoId: string, searchId: string | null = null) {
        super(photoId, searchId);
        this.recordId = recordId;
    }
}
export class LoadPersonPhotoRequest extends LoadPhotoRequest {
    personId: string;
    constructor(personId: string, photoId: string, searchId: string | null = null) {
        super(photoId, searchId);
        this.personId = personId;
    }
}
export class TextSearchQuery {
    public firstname: string | null = null;
    public lastname: string | null = null;
    public patronymic: string | null = null;
    public birthdayDay: number | null = null;
    public birthdayMonth: number | null = null;
    public birthdayYear: number | null = null;
    public passportSerial: string | null = null;
    public passportNumber: string | null = null;
    public passportIssueYear: number | null = null;
    public passportIssueMonth: number | null = null;
    public passportIssueDay: number | null = null;
    public phone: string | null = null;
    public email: string | null = null;
    public get hasPassport(): boolean {
        if (!this.passportSerial) {
            return false;
        }
        if (!this.passportNumber) {
            return false;
        }
        return true;
    }
    public get HasFirstnameAndLastname() {
        if (!this.firstname) {
            return false;
        }
        if (!this.lastname) {
            return false;
        }
        return true;
    }
    public get hasBirthday() {
        if (!this.birthdayYear) {
            return false;
        }
        if (!this.birthdayMonth) {
            return false;
        }
        if (!this.birthdayDay) {
            return false;
        }
        return true;
    }
    public get passportIssue(): Date | null {
        if (this.passportIssueDay === null) {
            return null;
        }
        if (this.passportIssueMonth === null) {
            return null;
        }
        if (this.passportIssueYear === null) {
            return null;
        }
        const date = new Date();
        date.setUTCFullYear(this.passportIssueYear, this.passportIssueMonth - 1, this.passportIssueDay);
        return date;
    }
    public set passportIssue(data: Date | null) {
        if (data === null) {
            this.passportIssueDay = null;
            this.passportIssueMonth = null;
            this.passportIssueYear = null;
        } else {
            this.passportIssueDay = data.getDate();
            this.passportIssueMonth = data.getMonth() + 1;
            this.passportIssueYear = data.getFullYear();
        }
    }
    public get birthday(): Date | null {
        if (this.birthdayDay === null) {
            return null;
        }
        if (this.birthdayMonth === null) {
            return null;
        }
        if (this.birthdayYear === null) {
            return null;
        }
        const date = new Date();
        date.setUTCFullYear(this.birthdayYear);
        date.setUTCMonth(this.birthdayMonth - 1);
        date.setUTCDate(this.birthdayDay);
        return date;
    }
    public set birthday(data: Date | null) {
        if (data === null) {
            this.birthdayDay = null;
            this.birthdayMonth = null;
            this.birthdayYear = null;
        } else {
            this.birthdayDay = data.getDate();
            this.birthdayMonth = data.getMonth() + 1;
            this.birthdayYear = data.getFullYear();
        }
    }
}
export enum RequestStatus {
    Created = 1,
    Process = 2,
    Completed = 4
}
export enum FeedType {
    Record = "record",
    Person = "person"
}
export enum Roles {
    Admin = "admin",
    Manager = "manager",
    User = "user"
}
export enum Mode {
    Classic = "classic",
    LogsiticsFree = "logistics_free",
    LogisticsPaid = "logistics_paid"
}
export class CompanyIncidentInfo {
    public recordCount: number | null = null;
}
export class PersonIncidentInfo {
    public recordCount: number | null = null;
    public uniquePersonCount: number | null = null;
}
export class PersonIncidentRange {
    public min: Date | null = null;
    public max: Date | null = null;
}
export class Notification {
    public id: string | null = null;
    public dateTime: Date | null = null;
    public level: string | null = null;
}
export class News {
    public id: string | null = null;
    public dateTime: Date | null = null;
    public message: string | null = null;
}
export abstract class FeedRecord {
    public personContext: PersonContext | null = null;
    public type: string | null = null;
}
export class FeedNotification extends FeedRecord {
    public notification: Notification | null = null;
    public objectId: string | null = null;
    public objectType: string | null = null;
    constructor() {
        super();
        this.type = "notification";
    }
}
export class RequestFeedRecord extends FeedRecord {
    public recordId: string | null = null;
    constructor() {
        super();
        this.type = "record";
    }
}
export class PersonFeedRecord extends FeedRecord {
    public personId: string | null = null;
    constructor() {
        super();
        this.type = "person";
    }
}
export class CompanyFeedRecord extends FeedRecord {
    public companyId: string;
    constructor(companyId: string, context: PersonContext) {
        super();
        this.companyId = companyId;
        this.personContext = context;
        this.type = "company";
    }
}
export class FileDescriptor {
    public readonly fileId: string;
    public readonly userId: string;
    public readonly name: string | null;
    constructor(fileId: string, userId: string, name: string | null) {
        this.fileId = fileId;
        this.userId = userId;
        this.name = name;
    }
}
export class FeedBatch {
    public metaData: any = null;
    public searchId: string | null = null;
    public session: string | null = null;
    public total: Number | null = null;
    public personContext: PersonContext = new PersonContext();
    public records: Array<FeedRecord> = new Array<FeedRecord>();
}
export class FeedDateFilter {
    public fromDateTime: Date | null = null;
    public tillDateTime: Date | null = null;
    public fromTimestamp: Date | null = null;
    public tillTimestamp: Date | null = null;
    public clear(): void {
        this.fromDateTime = null;
        this.tillDateTime = null;
        this.fromTimestamp = null;
        this.tillTimestamp = null;
    }
}
export class FeedSort {
    public sortField: string = "timestamp";
    public sortDesc: boolean = true;
}
export class FaceCompareFace {
    public faceId: string | null;
}
export class FaceCompareRequest {
    public faces: Array<FaceCompareFace> = new Array<FaceCompareFace>();
    public add(faceId: string): void {
        const face = new FaceCompareFace();
        face.faceId = faceId;
        this.faces.push(face);
    }
}
export class Phone {
    public readonly countryCode: number;
    public readonly nationalNumber: number;
    constructor(countryCode: number, nationalNumber: number) {
        this.countryCode = countryCode;
        this.nationalNumber = nationalNumber;
    }
    public getString(): string {
        const cc = this.countryCode.toString();
        const nn = this.nationalNumber.toString();
        return cc + nn;
    }
}
export class InfoPerson {
    public firstname: string | null = null;
    public lastname: string | null = null;
    public patronymic: string | null = null;
    public birthdayYear: number | null = null;
    public birthdayMonth: number | null = null;
    public birthdayDay: number | null = null;
    public address: string | null = null;
    public work: string | null = null;
    public phones: Array<number> | null = null;
    public emails: Array<string> | null = null;
}
export class Email {
    public readonly recipient: string;
    public readonly hostname: string;
    constructor(recipient: string, hostname: string) {
        this.recipient = recipient;
        this.hostname = hostname;
    }
    public getString(): string {
        return this.recipient + "@" + this.hostname;
    }
}
/** BEGIN ADMIN CLASSES **/
export class User extends Member {
    public roles: Array<string> | null = null;
    public balance: number | null = null;
    public isBlocked: boolean | null = null;
    public isSingleLogin: boolean | null = null;
    public login: string | null = null;
    public name: string | null = null;
    public expire: Date | null = null;
    public period: number | null = null;
    public mode: Mode | null | string = null;
}
export class AutogeneratedUser {
    public user: User = null;
    public password: string = null;
}
export class Price {
    public code: string | null;
    public price: number | null;
    constructor(code: string | null, price: number | null) {
        this.code = code;
        this.price = price;
    }
    public clone(): Price {
        return new Price(this.code, this.price);
    }
}
export class ThrottleConfiguration {
    public window: string | null;
    public windowSize: number | null;
    public requestsLeft: number | null;
    constructor(window: string | null, windowSize: number | null, requestsLeft: number | null) {
        this.window = window;
        this.windowSize = windowSize;
        this.requestsLeft = requestsLeft;
    }
    public clone(): ThrottleConfiguration {
        return new ThrottleConfiguration(this.window, this.windowSize, this.requestsLeft);
    }
    public static createDefault(): ThrottleConfiguration {
        return new ThrottleConfiguration("none", 0, null);
    }
}
export class MediaFaceInfo {
    public faceCount: number = null;
}
export class Coordinates {
    public lat: number | null = null;
    public lng: number | null = null;
}
export class ResolvedLocation extends Coordinates {
    public countryCode: string;
    public region: string;
    public state: string;
    public city: string;
    public street: string;
    public building: string;
    public address: string;
}
export class ResolvedCoordinates {
    public geoHash: string;
    public location: ResolvedLocation;
    constructor(geoHash: string, location: ResolvedLocation) {
        this.geoHash = geoHash;
        this.location = location;
    }
}
export class PersonWatchHitEntry {
    public objectId: string;
    public objectType: string;
}
export class PersonWatchHit {
    public personId: string;
    public hits: Array<PersonWatchHitEntry>;
}
export class CompanyFlags {
    public isPartnershipRisk: boolean | null = null;
    public isDefaultRisk: boolean | null = null;
    public isTaxRisk: boolean | null = null;
    public isCriminal: boolean | null = null;
    public isFraud: boolean | null = null;
}
export class Company {
    public companyId: string;
    public authorId: string;
    public status: number;
    public humanId: number | null = null;
    public readonly flags: CompanyFlags = new CompanyFlags();
    public name: string | null = null;
    public inn: string | null = null;
    constructor(companyId: string, authorId: string, status: number) {
        this.companyId = companyId;
        this.authorId = authorId;
        this.status = status;
    }
}
export class PersonContext {
    public bindingReasons: Map<string, string[]> = new Map<string, string[]>();
    public bindingCount: Map<string, number> = new Map<string, number>();
    public connections: Map<string, string[]> = new Map<string, string[]>();
    public companyAttributes: Map<string, any> = new Map<string, any>();
    public companyIncidentInfo: Map<string, CompanyIncidentInfo> = new Map<string, CompanyIncidentInfo>();
    public recordToCompany: Map<string, string[]> = new Map<string, string[]>();
    public avatars: Map<string, string> = new Map<string, string>();
    public incidentInfo: Map<string, PersonIncidentInfo> = new Map<string, PersonIncidentInfo>();
    public incidentRanges: Map<string, PersonIncidentRange> = new Map<string, PersonIncidentRange>();
    public resolvedLocations: Map<string, ResolvedLocation> = new Map<string, ResolvedLocation>();
    public faceInfo: Map<string, MediaFaceInfo> = new Map<string, MediaFaceInfo>();
    public files: Map<string, FileDescriptor> = new Map<string, FileDescriptor>();
    public users: Map<string, Member> = new Map<string, Member>();
    public news: Map<string, News> = new Map<string, News>();
    public persons: Map<string, RecordPerson> = new Map<string, RecordPerson>();
    public records: Map<string, Record> = new Map<string, Record>();
    public companies: Map<string, Company> = new Map<string, Company>();
    public hiddenPersons: Array<string> = new Array<string>();
    public watchedPersons: Array<string> = new Array<string>();
    public watchHits: Map<string, PersonWatchHit> = new Map<string, PersonWatchHit>();
    public photoScores: any;
    public searchId: string | null = null;
}
export class UserPersonUserAclRecord {
    public userIds: Array<string>;
}
export class UserPersonFlagAclRecord {
    public flags: RecordFlags;
}
export class UserPersonTagsAclRecord {
    public tags: Array<string>;
}
export class UserPersonAcl {
    public userAcl: UserPersonUserAclRecord;
    public flagAcl: UserPersonFlagAclRecord;
    public tagsAcl: UserPersonTagsAclRecord;
}
export class FeedSettings {
    status: RequestStatus = null;
    type: FeedType = null;
    flags: string = null;
}
export class WantedRecord {
    public name: string;
    public region: string;
    public dateTime: Date;
    public message: string;
    constructor(name: string, region: string, dateTime: Date, message: string) {
        this.name = name;
        this.region = region;
        this.dateTime = dateTime;
        this.message = message;
    }
}
export class PassportVerificationResult {
    public readonly isFound: boolean;
    public readonly isMatch: boolean;
    constructor(isFound: boolean, isMatch: boolean) {
        this.isFound = isFound;
        this.isMatch = isMatch;
    }
}
/** END ADMIN CLASSES **/
export class ApiCallError extends Error {
    public status: number;
    constructor(response: Response) {
        super();
        this.status = response.status;
    }
}
export class Api {
    secret: string;
    baseAddress: string | null;
    constructor(secret: string, baseAddress: string | null = null) {
        this.secret = secret;
        this.baseAddress = baseAddress;
    }
    public getAuth(): Promise<any> {
        return this.callGet("/3/auth");
    }
    public deleteAuth(): Promise<any> {
        return this.callDelete("/3/auth");
    }
    public createRecord(): Promise<string> {
        return this.callPost("/3/record", {}).then(_ => String(_));
    }
    public getRecord(id: string, searchId: string | null = null): Promise<Record> {
        const queryString = Api.buildQueryString({ search: searchId });
        return this.callGet("/3/record/" + id + "?" + queryString).then(_ => Api.readRecord(_));
    }
    public getPerson(id: string, searchId: string | null = null): Promise<RecordPerson> {
        const queryString = Api.buildQueryString({ search: searchId });
        return this.callGet("/3/person/" + id + "?" + queryString).then(_ => Api.readPerson(_));
    }
    public getCompany(companyId: string, searchId: string | null = null): Promise<Company> {
        const queryString = Api.buildQueryString({ search: searchId });
        return this.callGet("/3/company/" + companyId + "?" + queryString).then(_ => Api.readCompany(_));
    }
    public getCompanyAttributes(companyId: string, searchId: string | null = null): Promise<any> {
        const queryString = Api.buildQueryString({ search: searchId });
        return this.callGet("/3/company/" + companyId + "/attribute?" + queryString).then(_ => _);
    }
    public getPersonRecords(id: string, searchId: string | null = null): Promise<FeedBatch> {
        const queryString = Api.buildQueryString({ search: searchId, output: "feed" });
        return this.callGet("/3/person/" + id + "/record?" + queryString).then(_ => Api.readFeed(_));
    }
    public getCompanyRecords(companyId: string, searchId: string | null = null): Promise<FeedBatch> {
        const queryString = Api.buildQueryString({ search: searchId, output: "feed" });
        return this.callGet("/3/company/" + companyId + "/record?" + queryString).then(_ => Api.readFeed(_));
    }
    public getPersonCounters(id: string, searchId: string | null = null): Promise<any> {
        const queryString = Api.buildQueryString({ search: searchId });
        return this.callGet("/3/person/" + id + "/counter?" + queryString);
    }
    public getPersonAvatar(id: string, searchId: string | null = null): Promise<string | null> {
        const queryString = Api.buildQueryString({ search: searchId });
        return this.callGet("/3/person/" + id + "/avatar?" + queryString).then(_ => (typeof _ === "string" ? _ : null));
    }
    public disablePersonWatch(id: string, searchId: string | null = null): Promise<void> {
        const queryString = Api.buildQueryString({ search: searchId });
        return this.callDelete("/3/person/" + id + "/watch?" + queryString);
    }
    public enablePersonWatch(id: string, searchId: string | null = null): Promise<void> {
        const queryString = Api.buildQueryString({ search: searchId });
        return this.callPost("/3/person/" + id + "/watch?" + queryString);
    }
    public async getIsPersonOnWatch(id: string, searchId: string | null = null): Promise<boolean> {
        const queryString = Api.buildQueryString({ search: searchId });
        try {
            await this.callHead("/3/person/" + id + "/watch?" + queryString);
        } catch (error) {
            if (error.status === 404) {
                return false;
            }
            if (error.status === 204) {
                return false;
            }
            throw error;
        }
        return true;
    }
    public getRecordFileDescriptor(
        recordId: string,
        fileId: string,
        searchId: string | null = null
    ): Promise<FileDescriptor> {
        const queryString = Api.buildQueryString({ search: searchId });
        return this.callGet("/3/record/" + recordId + "/file/" + fileId + "/descriptor?" + queryString).then(_ =>
            Api.readFileDescriptor(_)
        );
    }
    public getPersonFileDescriptor(
        personId: string,
        fileId: string,
        searchId: string | null = null
    ): Promise<FileDescriptor> {
        const queryString = Api.buildQueryString({ search: searchId });
        return this.callGet("/3/person/" + personId + "/file/" + fileId + "/descriptor?" + queryString).then(_ =>
            Api.readFileDescriptor(_)
        );
    }
    public getFileDownloadLink(personId: string, fileId: string, searchId: string | null = null): string {
        let queryString = Api.buildQueryString({ search: searchId, __secret: this.secret });
        let endpoint = "/3/person/" + personId + "/file/" + fileId + "?" + queryString;
        return this.resolveFetchResourceUsingCurrentBaseAddres(endpoint);
    }
    public getRecordFileDownloadLink(recordId: string, fileId: string, searchId: string | null = null): string {
        let queryString = Api.buildQueryString({ search: searchId, __secret: this.secret });
        let endpoint = "/3/record/" + recordId + "/file/" + fileId + "?" + queryString;
        return this.resolveFetchResourceUsingCurrentBaseAddres(endpoint);
    }
    public downloadPersonFile(personId: string, fileId: string, searchId: string | null = null): void {
        let link = this.getFileDownloadLink(personId, fileId, searchId);
        window.open(link, "_blank");
    }
    public downloaRecordFile(personId: string, fileId: string, searchId: string | null = null): void {
        let link = this.getRecordFileDownloadLink(personId, fileId, searchId);
        window.open(link, "_blank");
    }
    public getPersonPhoto(personId: string, photoId: string, searchId: string | null = null): Promise<string> {
        return this.callGetBinaryAsBase64("/3/person/" + personId + "/photo/" + photoId, { search: searchId });
    }
    public getRecordPhoto(recordId: string, photoId: string, searchId: string | null = null): Promise<string> {
        return this.callGetBinaryAsBase64("/3/record/" + recordId + "/photo/" + photoId, { search: searchId });
    }
    public getRecordFileName(recordId: string, fileId: string, searchId: string | null = null): Promise<string> {
        const queryString = Api.buildQueryString({ search: searchId });
        return this.callGet("/3/record/" + recordId + "/file/" + fileId + "/name?" + queryString);
    }
    public getFileName(personId: string, fileId: string, searchId: string | null = null): Promise<string> {
        const queryString = Api.buildQueryString({ search: searchId });
        return this.callGet("/3/person/" + personId + "/file/" + fileId + "/name?" + queryString);
    }
    public getPersonAuthor(id: string, searchId: string | null = null): Promise<Member> {
        const queryString = Api.buildQueryString({ search: searchId });
        return this.callGet("/3/person/" + id + "/author?" + queryString).then(_ => Api.readMember(_));
    }
    public exportRecord(id: string, searchId: string | null = null): void {
        const queryString = Api.buildQueryString({ search: searchId, __secret: this.secret });
        let endpoint = "/3/record/" + id + "/export?" + queryString;
        let address = this.resolveFetchResourceUsingCurrentBaseAddres(endpoint);
        window.open(address, "_blank");
    }
    public exportPerson(id: string, searchId: string | null = null): void {
        const queryString = Api.buildQueryString({ search: searchId, __secret: this.secret });
        let endpoint = "/3/person/" + id + "/export?" + queryString;
        let address = this.resolveFetchResourceUsingCurrentBaseAddres(endpoint);
        window.open(address, "_blank");
    }
    public async exportUsers(userIdSet: Array<string>): Promise<void> {
        let path = this.resolveFetchResourceUsingCurrentBaseAddres("/3/user/export");
        let json = JSON.stringify({
            users: userIdSet
        });
        return new Promise(function(resolve, reject) {
            let xhr = new XMLHttpRequest();
            xhr.responseType = "arraybuffer";
            xhr.open("POST", path, true);
            xhr.setRequestHeader("Content-Type", "application/json");
            xhr.onload = function() {
                if (this.status == 200) {
                    let blob = new Blob([this.response]);
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = "users.xlsx";
                    link.click();
                    resolve();
                }
            };
            xhr.onerror = function() {
                reject();
            };
            xhr.send(json);
        });
    }
    public getRecordStatus(id: string, searchId: string | null = null): Promise<number> {
        const queryString = Api.buildQueryString({ search: searchId });
        return this.callGet("/3/record/" + id + "/status?" + queryString).then(_ => parseInt(_));
    }
    public getPersonBindings(
        id: string,
        searchId: string | null = null,
        reasons: Array<string> | null = null
    ): Promise<FeedBatch> {
        const queryParams: any = { output: "feed" };
        if (searchId !== null) {
            queryParams["search"] = searchId;
        }
        if (reasons !== null) {
            queryParams["reason"] = reasons.join(",");
        }
        const queryString = Api.buildQueryString(queryParams);
        return this.callGet("/3/person/" + id + "/binding?" + queryString).then(_ => Api.readFeed(_));
    }
    public getCompanyIncidentInfo(companyId: string, searchId: string | null = null): Promise<CompanyIncidentInfo> {
        let queryString = Api.buildQueryString({ search: searchId });
        return this.callGet("/3/company/" + companyId + "/incident?" + queryString).then(_ =>
            Api.readCompanyIncidentInfo(_)
        );
    }
    public getPersonIncidentInfo(personId: string, searchId: string | null = null): Promise<PersonIncidentInfo> {
        let queryString = Api.buildQueryString({ search: searchId });
        return this.callGet("/3/person/" + personId + "/incident?" + queryString).then(_ => Api.readIncidentInfo(_));
    }
    public getPersonBindingCounters(id: string, searchId: string | null = null): Promise<Object> {
        const queryString = Api.buildQueryString({ search: searchId });
        return this.callGet("/3/person/" + id + "/binding/counter?" + queryString);
    }
    public getIsPassportCompromised(series: string, number: string): Promise<boolean> {
        let payload = { passportSeries: series, passportNumber: number };
        return this.callPost("/3/util/passport/compromised", payload);
    }
    public getCurrentDateTime(): Promise<Date> {
        return this.callGet("/3/util/now").then(_ => new Date(_));
    }
    public resolveAddressIntoCoordinates(address: string): Promise<Coordinates> {
        let queryString = Api.buildQueryString({ address: address });
        return this.callPost("/3/util/geo/resolve/address?" + queryString).then(
            _ => Api.readCoordinates(_),
            _ => _
        );
    }
    public resolveCoordinatesIntoLocation(lat: number, lng: number): Promise<ResolvedCoordinates> {
        let queryString = Api.buildQueryString({ lat: lat, lng: lng });
        return this.callPost("/3/util/geo/resolve/coordinates?" + queryString).then(
            _ => Api.readResolvedCoordinates(_),
            _ => _
        );
    }
    public resolveRecordHumanId(humanId: number): Promise<string> {
        return this.callGet("/3/util/record/resolve/human?id=" + humanId).then(_ => _ as string);
    }
    public resolvePersonHumanId(humanId: number): Promise<string> {
        return this.callGet("/3/util/person/resolve/human?id=" + humanId).then(_ => _ as string);
    }
    public verifyWanted(fields: RecordFields): Promise<Array<WantedRecord>> {
        return this.callPost("/3/verification/individual/wanted", fields).then(_ =>
            Api.readWantedVerificationResult(_)
        );
    }
    public verifyPassportToFace(fields: RecordFields, fileId: string): Promise<PassportVerificationResult> {
        return this.callPost("/3/verification/passport/face?fileId=" + fileId, fields).then(_ =>
            Api.readPassportVerificationResult(_)
        );
    }
    public closeAlert(notificationId: string): Promise<void> {
        return this.callDelete("/3/alert/" + notificationId);
    }
    public getAlertFeed(): Promise<FeedBatch> {
        return this.callGet("/3/alert?output=feed").then(_ => Api.readFeed(_));
    }
    public getNotificationFeed(): Promise<FeedBatch> {
        return this.callGet("/3/notification?output=feed").then(_ => Api.readFeed(_));
    }
    public scrollNotificationFeed(session: string): Promise<FeedBatch> {
        let queryParams = { output: "feed", scroll: session };
        let queryString = Api.buildQueryString(queryParams);
        return this.callGet("/3/notification?" + queryString).then(_ => Api.readFeed(_));
    }
    public getWatch(): Promise<FeedBatch> {
        let queryParams = { output: "feed" };
        let queryString = Api.buildQueryString(queryParams);
        return this.callGet("/3/watch?" + queryString).then(_ => Api.readFeed(_));
    }
    public getRecordFeed(
        status: RequestStatus | null = null,
        flagFilter: string | null = null,
        dateFilter: FeedDateFilter | null = null,
        sort: FeedSort | null = null,
        scroll: string | null = null
    ): Promise<FeedBatch> {
        let statusFilter = null;
        if (status !== null) {
            statusFilter = RequestStatus[status];
        }
        const queryParams: any = {};
        if (scroll !== null) {
            queryParams["scroll"] = scroll;
        }
        if (statusFilter !== null) {
            queryParams["status"] = statusFilter;
        }
        if (flagFilter !== null) {
            queryParams["flag"] = flagFilter;
        }
        if (!!dateFilter) {
            if (!!dateFilter.fromDateTime) {
                queryParams["fromDateTime"] = dateFilter.fromDateTime;
            }
            if (!!dateFilter.tillDateTime) {
                queryParams["tillDateTime"] = dateFilter.tillDateTime;
            }
            if (!!dateFilter.fromTimestamp) {
                queryParams["fromTimestamp"] = dateFilter.fromTimestamp;
            }
            if (!!dateFilter.tillTimestamp) {
                queryParams["tillTimestamp"] = dateFilter.tillTimestamp;
            }
        }
        if (!!sort) {
            if (!!sort.sortField) {
                queryParams["sort"] = sort.sortField;
            }
            queryParams["desc"] = sort.sortDesc === true;
        }
        const queryString = Api.buildQueryString(queryParams);
        return this.callGet("/3/feed/record?" + queryString).then(_ => Api.readFeed(_));
    }
    public getPersonFeed(
        status: RequestStatus | null = null,
        flagFilter: string | null = null,
        dateFilter: FeedDateFilter | null = null,
        sort: FeedSort | null = null,
        scroll: string | null = null
    ): Promise<FeedBatch> {
        let statusFilter = null;
        if (status !== null) {
            statusFilter = RequestStatus[status];
        }
        const queryParams: any = {};
        if (scroll !== null) {
            queryParams["scroll"] = scroll;
        }
        if (statusFilter !== null) {
            queryParams["status"] = statusFilter;
        }
        if (flagFilter !== null) {
            queryParams["flag"] = flagFilter;
        }
        if (!!dateFilter) {
            if (!!dateFilter.fromDateTime) {
                queryParams["fromDateTime"] = dateFilter.fromDateTime;
            }
            if (!!dateFilter.tillDateTime) {
                queryParams["tillDateTime"] = dateFilter.tillDateTime;
            }
            if (!!dateFilter.fromTimestamp) {
                queryParams["fromTimestamp"] = dateFilter.fromTimestamp;
            }
            if (!!dateFilter.tillTimestamp) {
                queryParams["tillTimestamp"] = dateFilter.tillTimestamp;
            }
        }
        if (!!sort) {
            if (!!sort.sortField) {
                queryParams["sort"] = sort.sortField;
            }
            queryParams["desc"] = sort.sortDesc === true;
        }
        const queryString = Api.buildQueryString(queryParams);
        return this.callGet("/3/feed/person?" + queryString).then(_ => Api.readFeed(_));
    }
    public removeRecord(requestId: string): Promise<void> {
        return this.callDelete("/3/record/" + requestId);
    }
    public removePersonFromRecord(recordId: string, personId: string): Promise<void> {
        return this.callDelete("/3/record/" + recordId + "/person/" + personId);
    }
    public sendBalanceRefillRequest(request: UserBalanceRefillRequest): Promise<UserBalanceRefillResponse> {
        return this.callPost("/3/balance/refill", request).then(_ => Api.readBalanceRefillResponse(_));
    }
    public detectFaces(fileId: string): Promise<Array<PhotoBound>> {
        return this.callGet("/3/util/face/detect?rotate=zero&file=" + fileId + "&fileId=" + fileId).then(_ =>
            _.map(i => this.readPhotoBound(i))
        );
    }
    public cropFile(fileId: string, bound: PhotoBound): Promise<string> {
        return this.callGetBinaryAsBase64("/3/util/image/crop", {
            file: fileId,
            x1: bound.x1,
            y1: bound.y1,
            x2: bound.x2,
            y2: bound.y2,
            rotate: bound.rotate
        });
    }
    public removePhotoFromRecord(recordId: string, photoId: string): Promise<void> {
        return this.callDelete("/3/record/" + recordId + "/photo/" + photoId);
    }
    public removePhotoFromPerson(personId: string, photoId: string): Promise<void> {
        return this.callDelete("/3/person/" + personId + "/photo/" + photoId);
    }
    public removeFileFromPerson(personId: string, fileId: string): Promise<void> {
        return this.callDelete("/3/person/" + personId + "/file/" + fileId);
    }
    public removeFileFromRecord(recordId: string, fileId: string): Promise<void> {
        return this.callDelete("/3/record/" + recordId + "/file/" + fileId);
    }
    public commitRecord(id: string): Promise<void> {
        return this.callPost("/3/record/" + id + "/commit");
    }
    public commitPerson(id: string): Promise<void> {
        return this.callPost("/3/person/" + id + "/commit");
    }
    public createCompany(): Promise<string> {
        return this.callPost("/3/company");
    }
    public connectCompanyWithIncident(recordId: string, companyId: string): Promise<void> {
        return this.callPut("/3/record/" + recordId + "/company/" + companyId);
    }
    public removeCompanyFromIncident(recordId: string, companyId: string): Promise<void> {
        return this.callDelete("/3/record/" + recordId + "/company/" + companyId);
    }
    public setCompanyINN(companyId: string, companyInn: string): Promise<void> {
        return this.callPut("/3/company/" + companyId + "/inn", companyInn);
    }
    public setCompanyFlags(companyId: string, companyFlags: CompanyFlags): Promise<void> {
        return this.callPut("/3/company/" + companyId + "/flag", companyFlags);
    }
    public replaySearch(searchId: string, sort: string, desc: boolean): Promise<FeedBatch> {
        const args = Api.buildQueryString({ output: "feed", sort: sort, desc: desc });
        return this.callPost("/3/search/" + searchId + "/replay?" + args).then(_ => Api.readFeed(_));
    }
    public searchByText(query: TextSearchQuery): Promise<FeedBatch> {
        return this.callPost("/3/search/text?output=feed", query).then(_ => Api.readFeed(_));
    }
    public searchScroll(searchId: string, scroll: string): Promise<FeedBatch> {
        return this.callGet("/3/search/" + searchId + "?output=feed&scroll=" + scroll).then(_ => Api.readFeed(_));
    }
    public parsePhone(expression: string): Promise<Phone | null> {
        if (!expression) {
            return Promise.resolve(null);
        }
        return this.callPost("/3/util/phone/parse", expression).then(_ => this.readPhone(_));
    }
    public parseEmail(expression: string): Promise<Email | null> {
        if (!expression) {
            return Promise.resolve(null);
        }
        return this.callPost("/3/util/email/parse", expression).then(_ => this.readEmail(_));
    }
    public searchPersonInfoByPhone(phone: string): Promise<Array<InfoPerson>> {
        return this.callPost("/3/util/info/person/phone", { phone: phone }).then(_ =>
            _.map(i => Api.readInfoPerson(i))
        );
    }
    public searchPhoneBook(phone: string): Promise<Array<string>> {
        return this.callPost("/3/util/info/phone_book", { phone: phone }).then(_ => Api.readPhoneBookResult(_));
    }
    public searchPersonInfoByEmail(email: string): Promise<Array<InfoPerson>> {
        return this.callPost("/3/util/info/person/email", { email: email }).then(_ =>
            _.map(i => Api.readInfoPerson(i))
        );
    }
    public recognizeDocument(fileId: string): Promise<RecognizedDocument[]> {
        let queryString = Api.buildQueryString({ fileId: fileId });
        return this.callGet("/3/util/document/recognize?" + queryString).then(_ =>
            _.documents.map(_ => Api.readRecognizedDocument(_))
        );
    }
    public searchPersonInfoByName(query: TextSearchQuery): Promise<Array<InfoPerson>> {
        return this.callPost("/3/util/info/person/name", {
            firstname: query.firstname,
            lastname: query.lastname,
            patronymic: query.patronymic,
            birthdayDay: query.birthdayDay,
            birthdayMonth: query.birthdayMonth,
            birthdayYear: query.birthdayYear
        }).then(_ => _.map(i => Api.readInfoPerson(i)));
    }
    public async uploadFile(file: Blob): Promise<string> {
        let result = await this.callFileUpload("/3/file/", file);
        return result;
    }
    public searchByFace(fileId: string, allowMultiFaceSearch: boolean): Promise<FeedBatch> {
        let params = {
            fileId: fileId,
            allowMultiFaceSearch: allowMultiFaceSearch
        };
        return this.callPost("/3/search/face?output=feed", params).then(_ => Api.readFeed(_));
    }
    public createPerson(recordId: string): Promise<string> {
        return this.callPost("/3/record/" + recordId + "/person").then(_ => String(_));
    }
    public bindPerson(recordId: string, personId: string): Promise<string> {
        return this.callPut("/3/record/" + recordId + "/person/" + personId).then(_ => String(_));
    }
    public async attachFile(personId: string, file: any, name: string): Promise<any> {
        let queryString = Api.buildQueryString({ name: name });
        let endpoint = "/3/person/" + personId + "/file?" + queryString;
        let result = await this.callFileUpload(endpoint, file);
        return result;
    }
    public attachRecordFile(recordId: string, file: any, name: string): Promise<any> {
        let queryString = Api.buildQueryString({ name: name });
        let endpoint = "/3/record/" + recordId + "/file?" + queryString;
        return this.callFileUpload(endpoint, file);
    }
    public async detectFile(blob: Blob): Promise<FileDetectResult> {
        let result = await this.callFileUpload("/3/util/file/detect", blob);
        return Api.readFileDetectResult(result);
    }
    public setPersonFields(personId: string, fields: RecordFields): Promise<void> {
        return this.callPut("/3/person/" + personId + "/field", fields);
    }
    public setRecordDateTime(recordId: string, value: Date): Promise<void> {
        return this.callPut("/3/record/" + recordId + "/date_time", { value: value.toISOString() });
    }
    public setPersonDateTime(personId: string, value: Date): Promise<void> {
        return this.callPut("/3/person/" + personId + "/dateTime", { value: value });
    }
    public setPersonContacts(personId: string, contacts: RecordContacts): Promise<void> {
        return this.callPut("/3/person/" + personId + "/contact", contacts);
    }
    public setRecordComment(recordId: string, comment: string): Promise<void> {
        return this.callPut("/3/record/" + recordId + "/comment", { value: comment });
    }
    public setPersonComment(personId: string, comment: string): Promise<void> {
        return this.callPut("/3/person/" + personId + "/comment", { value: comment });
    }
    public setPersonFable(personId: string, fable: string): Promise<void> {
        return this.callPut("/3/person/" + personId + "/fable", { value: fable });
    }
    public setPersonFlags(personId: string, flags: RecordFlags): Promise<void> {
        return this.callPut("/3/person/" + personId + "/flag", flags);
    }
    public setRecordFlags(recordId: string, flags: RecordFlags): Promise<void> {
        return this.callPut("/3/record/" + recordId + "/flag", flags);
    }
    public setRecordLocation(recordId: string, location: Location): Promise<void> {
        const payload = {
            lat: location.lat,
            lng: location.lng
        };
        return this.callPut("/3/record/" + recordId + "/location", payload);
    }
    public setRecordLoss(recordId: string, loss: number): Promise<void> {
        return this.callPut("/3/record/" + recordId + "/loss", { value: loss });
    }
    public compareFaces(request: FaceCompareRequest): Promise<FaceCompareResult> {
        return this.callPost("/3/util/face/compare", request).then(_ => this.readFaceCompareResult(_));
    }
    public searchSimilar(recordId: string, filter: string | null = null): Promise<SimilarSearchResult> {
        const queryString = Api.buildQueryString({ output: "feed", filter: filter });
        return this.callPost("/3/search/similar?" + queryString, { recordId: recordId }).then(_ =>
            Api.readSimilarSearchResult(_)
        );
    }
    /** BEGIN ADMIN API CALLS **/
    public getLocks(): Promise<any> {
        return this.callGet("/3/lock");
    }
    public releaseLock(lockKey: string, lockId: string): Promise<void> {
        return this.callDelete("/3/lock/" + lockKey, { lockId: lockId });
    }
    public acquireLock(lockKey: string): Promise<string> {
        return this.callGet("/3/lock/" + lockKey);
    }
    public getUserSessions(): Promise<Array<UserSession>> {
        return this.callGet("/3/session").then(_ =>
            Object.keys(_).map(k => {
                let session = new UserSession();
                session.user = Api.readUser(_[k]);
                session.secret = k;
                return session;
            })
        );
    }
    public terminateSession(userId: string, secret: string): Promise<void> {
        return this.callDelete("/3/user/" + userId + "/session/" + secret);
    }
    public getPersonDataTags(): Promise<Array<string>> {
        return this.callGet("/3/util/person/tag");
    }
    public getUserPersonAcl(userId: string): Promise<UserPersonAcl> {
        return this.callGet("/3/user/" + userId + "/acl").then(_ => Api.readUserPersonAcl(_));
    }
    public setUserPersonAcl(userId: string, acl: UserPersonAcl): Promise<void> {
        let payload = {} as any;
        if (acl.flagAcl) {
            payload.flagAcl = {};
            payload.flagAcl.flags = acl.flagAcl.flags;
        }
        if (acl.tagsAcl) {
            payload.tagsAcl = {};
            payload.tagsAcl.tags = acl.tagsAcl.tags;
        }
        if (acl.userAcl) {
            payload.userAcl = {};
            payload.userAcl.userIds = acl.userAcl.userIds;
        }
        return this.callPut("/3/user/" + userId + "/acl", payload);
    }
    public setUserBalance(userId: string, balance: number): Promise<void> {
        return this.callPut("/3/user/" + userId + "/balance", { value: balance });
    }
    public getUserBalance(userId: string): Promise<Number> {
        return this.callGet("/3/user/" + userId + "/balance");
    }
    public getPrices(): Promise<Array<Price>> {
        return this.callGet("/3/price").then(_ => _.map(p => Api.readPrice(p)));
    }
    public getUserPrices(userId: string): Promise<Array<Price>> {
        return this.callGet("/3/user/" + userId + "/price").then(_ => _.map(p => Api.readPrice(p)));
    }
    public getUserThrottle(userId: string): Promise<Map<string, ThrottleConfiguration>> {
        return this.callGet("/3/user/" + userId + "/throttle").then(_ => {
            let result = new Map<string, ThrottleConfiguration>();
            Object.keys(_).forEach(k => {
                let configuration = Api.readThrottleConfiguration(_[k]);
                result.set(k, configuration);
            });
            return result;
        });
    }
    public getThrottle(): Promise<Map<string, ThrottleConfiguration>> {
        return this.callGet("/3/throttle").then(_ => {
            let result = new Map<string, ThrottleConfiguration>();
            Object.keys(_).forEach(k => {
                let configuration = Api.readThrottleConfiguration(_[k]);
                result.set(k, configuration);
            });
            return result;
        });
    }
    public setThrottle(code: string, window: string, windowSize: number): Promise<void> {
        let requestBody = {
            window: window,
            count: windowSize
        };
        return this.callPut("/3/throttle/" + code, requestBody);
    }
    public setUserThrottleCounterValue(userId: string, operation: string, value: number): Promise<void> {
        return this.callPut("/3/user/" + userId + "/throttle/" + operation + "/counter", { value: value });
    }
    public setPrice(code: string, price: number): Promise<void> {
        return this.callPut("/3/price/" + code, { value: price });
    }
    public setUserPrice(userId: string, code: string, price: number): Promise<void> {
        return this.callPut("/3/user/" + userId + "/price/" + code, { value: price });
    }
    public setUserThrottle(userId: string, code: string, window: string, windowSize: number): Promise<void> {
        let requestBody = {
            window: window,
            count: windowSize
        };
        return this.callPut("/3/user/" + userId + "/throttle/" + code, requestBody);
    }
    public buildBatchMetrics(userIds: Array<string>, from: Date, till: Date): Promise<any> {
        const body = { userIds: userIds, from: from, till: till };
        return this.callPost("/3/user/batch/metric", body);
    }
    public buildBatchBillingGrouping(userIds: Array<string>, from: Date, till: Date): Promise<any> {
        let body = { userIds: userIds, from: from, till: till };
        return this.callPost("/3/user/batch/billing/group", body);
    }
    public setUserStatusBatch(userIds: Array<string>, isActive: boolean): Promise<void> {
        const body = { userIds: userIds, isActive: isActive };
        return this.callPost("/3/user/batch/status", body);
    }
    public setUserPricaBatch(userIds: Array<string>, code: string, price: number): Promise<void> {
        const body = { userIds: userIds, code: code, value: price };
        return this.callPost("/3/user/batch/price/", body);
    }
    public setUserBalanceBatch(userIds: Array<string>, price: number): Promise<void> {
        const body = { userIds: userIds, value: price };
        return this.callPost("/3/user/batch/balance", body);
    }
    public getUsers(): Promise<Array<User>> {
        return this.callGet("/3/user").then(_ => _.map(i => Api.readUser(i)));
    }
    public getUserStats(): Promise<any> {
        return this.callGet("/3/user/stat");
    }
    public getUser(userId: string): Promise<User> {
        return this.callGet("/3/user/" + userId).then(_ => Api.readUser(_));
    }
    public getUserSecrets(userId: string): Promise<Array<string>> {
        return this.callGet("/3/user/" + userId + "/secret");
    }
    public grantRole(userId: string, role: string): Promise<void> {
        return this.callPut("/3/user/" + userId + "/role/" + role);
    }
    public revokeRole(userId: string, role: string): Promise<void> {
        return this.callDelete("/3/user/" + userId + "/role/" + role);
    }
    public issueSecret(userId: string): Promise<string> {
        return this.callPost("/3/user/" + userId + "/secret");
    }
    public revokeSecret(userId: string, secret: string): Promise<void> {
        return this.callDelete("/3/user/" + userId + "/secret/" + secret);
    }
    public createUser(login: string): Promise<string> {
        return this.callPost("/3/user", { login: login }).then(_ => String(_));
    }
    public createMulti(login: string, count: number): Promise<Array<AutogeneratedUser>> {
        return this.callPost("/3/user/batch/create", { login: login, count: count }).then(_ =>
            _.map(_ => Api.readAutogeneratedUser(_))
        );
    }
    public impersonate(userId: string): Promise<string> {
        return this.callPost("/3/user/" + userId + "/impersonate").then(_ => String(_));
    }
    public setUserPassword(userId: string, password: string): Promise<string> {
        return this.callPut("/3/user/" + userId + "/password", { value: password });
    }
    public setUserName(userId: string, name: string): Promise<string> {
        return this.callPut("/3/user/" + userId + "/name", { value: name });
    }
    public setUserExpire(userId: string, expire: Date | null): Promise<void> {
        return this.callPut("/3/user/" + userId + "/expire", { value: expire });
    }
    public setUserPeriod(userId: string, period: number | null): Promise<void> {
        return this.callPut("/3/user/" + userId + "/period", { value: period });
    }
    public setUserMode(userId: string, mode: Mode | string): Promise<void> {
        return this.callPut("/3/user/" + userId + "/mode", { value: mode });
    }
    public getUserLog(userId: string, session: string | null = null): Promise<UserLogBatch> {
        const args = Api.buildQueryString({ scroll: session });
        return this.callGet("/3/user/" + userId + "/log?" + args).then(_ => Api.readUserLogBatch(_));
    }
    public getUserLogDays(userId: string): Promise<Array<UserLogDate>> {
        return this.callGet("/3/user/" + userId + "/log/days").then(_ => _.map(i => Api.readUserLogDay(i)));
    }
    public getUserSearchSessionDays(userId: string): Promise<Array<UserLogDate>> {
        return this.callGet("/3/user/" + userId + "/search").then(_ => _.map(i => Api.readUserLogDay(i)));
    }
    public getDailyUserLog(userId: string, year: number, month: number, day: number) {
        return this.callGet("/3/user/" + userId + "/log/" + year + "/" + month + "/" + day).then(_ =>
            _.map(i => Api.readUserLogRecord(i))
        );
    }
    public getUserLogGrouping(userId: string, from: Date, till: Date): Promise<any> {
        const fromString = from.toISOString();
        const tillString = till.toISOString();
        const args = Api.buildQueryString({ from: fromString, till: tillString });
        return this.callGet("/3/user/" + userId + "/log/group?" + args);
    }
    public getUserBillingGrouping(userId: string, from: Date, till: Date): Promise<any> {
        const fromString = from.toISOString();
        const tillString = till.toISOString();
        const args = Api.buildQueryString({ from: fromString, till: tillString });
        return this.callGet("/3/user/" + userId + "/billing/group?" + args);
    }
    public getUserSearchMetrics(userId: string, from: Date, till: Date): Promise<any> {
        const fromString = from.toISOString();
        const tillString = till.toISOString();
        const args = Api.buildQueryString({ from: fromString, till: tillString });
        return this.callGet("/3/user/" + userId + "/search/metric?" + args);
    }
    public exportUserLogGrouping(userId: string, from: Date, till: Date, format: string): void {
        const fromString = from.toISOString();
        const tillString = till.toISOString();
        const queryStringArgs = {
            from: fromString,
            till: tillString,
            export: true,
            format: format,
            __secret: this.secret
        };
        const args = Api.buildQueryString(queryStringArgs);
        window.open("/api/3/user/" + userId + "/log/group?" + args, "_blank");
    }
    public blockUser(userId: string): Promise<void> {
        return this.callPut("/3/user/" + userId + "/blocked");
    }
    public unblockUser(userId: string): Promise<void> {
        return this.callDelete("/3/user/" + userId + "/blocked");
    }
    public turnSignleLoginOn(userId: string): Promise<void> {
        return this.callPut("/3/user/" + userId + "/singleLogin");
    }
    public turnSignleLoginOff(userId: string): Promise<void> {
        return this.callDelete("/3/user/" + userId + "/singleLogin");
    }
    public setConfigBooleanParam(name: string, value: boolean): Promise<void> {
        return this.callPut("/3/config/" + name + "/bool", { value: value });
    }
    public getConfig(): Promise<any> {
        return this.callGet("/3/config");
    }
    public commitConfig(paramName: string, paramType: string, value: string | boolean | number | null): Promise<void> {
        return this.callPut("/3/config/" + paramName + "/" + paramType, { value: value });
    }
    public removeConfig(paramName: string): Promise<void> {
        return this.callDelete("/3/config/" + paramName);
    }
    public getPersonStats(): Promise<any> {
        return this.callGet("/3/stats/person");
    }
    public getUserSearchStats(): Promise<any> {
        return this.callGet("/3/stats/user/search");
    }
    public getUserSearchCounters(year: number, month: number, day: number): Promise<any> {
        return this.callGet("/3/stats/user/search/" + year + "/" + month + "/" + day);
    }
    /** END ADMIN API CALLS **/
    public static redirectToLogin(): void {
        window.location.href = "/login.html?referer=" + location.pathname + location.hash;
    }
    public static redirectToMain(): void {
        window.location.href = "/index.html";
    }
    private callHead(endpoint: string): Promise<any> {
        return this.callAjax(endpoint, "HEAD");
    }
    private callGet(endpoint: string): Promise<any> {
        return this.callAjax(endpoint, "GET");
    }
    private callDelete(endpoint: string, payload?: any): Promise<any> {
        return this.callAjax(endpoint, "DELETE", payload);
    }
    private callPut(endpoint: string, payload?: any): Promise<any> {
        return this.callAjax(endpoint, "PUT", payload);
    }
    private callPost(endpoint: string, payload?: any): Promise<any> {
        return this.callAjax(endpoint, "POST", payload);
    }
    private async callGetBinaryAsBase64(endpoint: string, queryStringArgs: object): Promise<string> {
        let queryString = Api.buildQueryString(queryStringArgs);
        let fullAddress = endpoint + "?" + queryString;
        let response = await this.callGet(fullAddress);
        let base64 = await Api.convertBlobToBase64(response);
        return base64;
    }
    private callAjax(endpoint: string, method: string, payload?: any): Promise<any> {
        let params: any = {
            method: method,
            headers: {
                "Content-Type": "application/json",
                "X-API-Secret": this.secret
            },
            body: null
        };
        if (payload) {
            params.body = JSON.stringify(payload);
        }
        return this.callFetch(endpoint, params);
    }
    private callFileUpload(endpoint: string, blob: Blob): Promise<any> {
        return this.callFetch(endpoint, {
            method: "POST",
            headers: {
                "Content-Type": "application/octet-stream",
                "X-API-Secret": this.secret
            },
            body: blob
        });
    }
    private async callFetch(endpoint: string, params: object): Promise<any> {
        let resolvedEndpoint = this.resolveFetchResourceUsingCurrentBaseAddres(endpoint);
        let response = await fetch(resolvedEndpoint, params);
        let result = await this.processApiCallResponse(response);
        return result;
    }
    public async getDocumentation(role: Roles): Promise<string> {
        return this.callGet("/3/help/" + role);
    }
    public setDocumentation(role: Roles, markdownContent: string): Promise<void> {
        return this.callPut("/3/help/" + role, { value: markdownContent });
    }
    private resolveFetchResourceUsingCurrentBaseAddres(endpoint: string): string {
        return Api.resolveFetchResource(endpoint, this.baseAddress);
    }
    private async processApiCallResponse(result: Response): Promise<any> {
        if (result.status === 401) {
            Api.redirectToLogin();
        } else if (result.status === 200) {
            let contentType = Api.parseContentType(result);
            if (contentType === "application/json") {
                let json = await result.json();
                if (json) {
                    return json["result"];
                }
                return undefined;
            } else if (contentType === "image/png" || contentType === "application/octet-stream") {
                let blob = await result.blob();
                return blob;
            } else {
                return result.body;
            }
        } else {
            console.log(result);
            throw new ApiCallError(result);
        }
    }
    private static parseContentType(response: Response): string | null {
        let headerValue = response.headers.get("Content-Type");
        if (!headerValue) {
            return null;
        }
        let split = headerValue.split(";");
        for (let i = 0; i < split.length; i++) {
            split[i] = split[i].trim();
        }
        return split[0];
    }
    private static readFileDetectResult(data: any): FileDetectResult {
        const result = new FileDetectResult();
        result.type = data.type;
        return result;
    }
    private static readWantedVerificationResult(data: any): Array<WantedRecord> {
        let result = new Array<WantedRecord>();
        for (let i = 0; i < data.length; i++) {
            result[i] = Api.readWantedRecord(data[i]);
        }
        return result;
    }
    private static readCoordinates(data: any): Coordinates | null {
        if (!data) {
            return null;
        }
        let result = new Coordinates();
        result.lat = data.lat;
        result.lng = data.lng;
        return result;
    }
    private static readResolvedCoordinates(data: any): ResolvedCoordinates {
        let location = Api.readResolvedLocation(data.location);
        let geoHash = data.geoHash as string;
        return new ResolvedCoordinates(geoHash, location);
    }
    private static readWantedRecord(data: any): WantedRecord {
        return new WantedRecord(data.name, data.region, data.dateTime, data.message);
    }
    private static readPassportVerificationResult(data: any): PassportVerificationResult {
        const isFound = typeof data.isFound === "boolean" ? data.isFound : null;
        const isMatch = typeof data.isMatch === "boolean" ? data.isMatch : null;
        return new PassportVerificationResult(isFound, isMatch);
    }
    private static readFeed(data: any): FeedBatch {
        let batch = new FeedBatch();
        batch.metaData = data.metaData || {};
        batch.total = parseInt(data.total);
        if (data.session) {
            batch.session = String(data.session);
        }
        if (data.searchId) {
            batch.searchId = String(data.searchId);
            batch.personContext.searchId = String(data.searchId);
        }
        if (batch.metaData) {
            if (batch.metaData.reasonMap) {
                Object.keys(batch.metaData.reasonMap).forEach(_ => {
                    batch.personContext.bindingReasons.set(_, batch.metaData.reasonMap[_]);
                });
            }
            batch.personContext.photoScores = batch.metaData.photoScoreMap;
        }
        Object.keys(data.incidentInfo).forEach(_ => {
            let incidentInfo = Api.readIncidentInfo(data.incidentInfo[_]);
            batch.personContext.incidentInfo.set(_, incidentInfo);
        });
        Object.keys(data.incidentRanges).forEach(_ => {
            let incidentRange = Api.readIncidentRange(data.incidentRanges[_]);
            batch.personContext.incidentRanges.set(_, incidentRange);
        });
        Object.keys(data.resolvedLocations).forEach(_ => {
            let location = Api.readResolvedLocation(data.resolvedLocations[_]);
            batch.personContext.resolvedLocations.set(_, location);
        });
        Object.keys(data.bindingCount).forEach(_ => {
            batch.personContext.bindingCount.set(_, data.bindingCount[_]);
        });
        Object.keys(data.avatars).forEach(_ => {
            batch.personContext.avatars.set(_, data.avatars[_]);
        });
        Object.keys(data.faceInfo).forEach(_ => {
            let faceInfo = Api.readFaceInfo(data.faceInfo[_]);
            batch.personContext.faceInfo.set(_, faceInfo);
        });
        Object.keys(data.connections).forEach(_ => {
            batch.personContext.connections.set(_, data.connections[_]);
        });
        Object.keys(data.recordToCompany).forEach(_ => {
            batch.personContext.recordToCompany.set(_, data.recordToCompany[_]);
        });
        Object.keys(data.companyAttributeSet).forEach(_ => {
            batch.personContext.companyAttributes.set(_, data.companyAttributeSet[_]);
        });
        Object.keys(data.companyIncidentInfo).forEach(_ => {
            let info = Api.readCompanyIncidentInfo(data.companyIncidentInfo[_]);
            batch.personContext.companyIncidentInfo.set(_, info);
        });
        Object.keys(data.personSet).forEach(_ => {
            let person = Api.readPerson(data.personSet[_]);
            batch.personContext.persons.set(_, person);
        });
        Object.keys(data.recordSet).forEach(_ => {
            let record = Api.readRecord(data.recordSet[_]);
            batch.personContext.records.set(_, record);
        });
        Object.keys(data.news).forEach(_ => {
            let news = Api.readNews(data.news[_]);
            batch.personContext.news.set(_, news);
        });
        Object.keys(data.watchHits).forEach(_ => {
            let hit = Api.readPersonWatchHit(data.watchHits[_]);
            batch.personContext.watchHits.set(_, hit);
        });
        Object.keys(data.companySet).forEach(_ => {
            let company = Api.readCompany(data.companySet[_]);
            batch.personContext.companies.set(_, company);
        });
        for (let i = 0; i < data.users.length; i++) {
            const member = Api.readMember(data.users[i]);
            batch.personContext.users.set(member.id, member);
        }
        for (let i = 0; i < data.files.length; i++) {
            let fileDescriptor = Api.readFileDescriptor(data.files[i]);
            batch.personContext.files.set(fileDescriptor.fileId, fileDescriptor);
        }
        for (let i = 0; i < data.hiddenPersons.length; i++) {
            batch.personContext.hiddenPersons.push(data.hiddenPersons[i]);
        }
        for (let i = 0; i < data.watchedPersons.length; i++) {
            batch.personContext.watchedPersons.push(data.watchedPersons[i]);
        }
        batch.records = data.records.map(_ => {
            let item = Api.readFeedItem(_);
            item.personContext = batch.personContext;
            return item;
        });
        return batch;
    }
    private static readFeedItem(data: any): FeedRecord {
        let result: FeedRecord;
        if (data.type === "person") {
            result = Api.readFeedPerson(data);
        } else if (data.type === "record") {
            result = Api.readFeedRecord(data);
        } else if (data.type === "notification") {
            result = Api.readFeedNotification(data);
        } else {
            throw new Error("Failed to resolve a Feed Item for " + data.type);
        }
        return result;
    }
    private static readFeedPerson(data: any): PersonFeedRecord {
        let item = new PersonFeedRecord();
        item.personId = data.personId;
        return item;
    }
    private static readFeedNotification(data: any): FeedNotification {
        let result = new FeedNotification();
        result.notification = Api.readNotification(data.notification);
        result.objectId = data.objectId;
        result.objectType = data.objectType;
        return result;
    }
    private static readFeedRecord(data: any): RequestFeedRecord {
        let item = new RequestFeedRecord();
        item.recordId = data.recordId;
        return item;
    }
    private static readNotification(data: any): Notification {
        let result = new Notification();
        result.id = data.id || null;
        result.dateTime = new Date(data.dateTime);
        result.level = data.level || null;
        return result;
    }
    private static readNews(data: any): News {
        let result = new News();
        result.id = data.id || null;
        result.dateTime = new Date(data.dateTime);
        result.message = data.message || null;
        return result;
    }
    private static readPersonWatchHit(data: any): PersonWatchHit {
        let result = new PersonWatchHit();
        result.personId = data.personId || null;
        result.hits = new Array<PersonWatchHitEntry>();
        for (let i = 0; i < data.hits.length; i++) {
            let entry = Api.readPersonWatchHitEntry(data.hits[i]);
            result.hits.push(entry);
        }
        return result;
    }
    private static readCompanyIncidentInfo(data: any): CompanyIncidentInfo {
        let result = new CompanyIncidentInfo();
        result.recordCount = data.recordCount;
        return result;
    }
    private static readCompany(data: any): Company {
        let result = new Company(data.companyId, data.authorId, data.status);
        result.humanId = data.humanId;
        result.inn = data.inn;
        result.name = data.name;
        result.flags.isPartnershipRisk = data.flags.isPartnershipRisk;
        result.flags.isDefaultRisk = data.flags.isDefaultRisk;
        result.flags.isCriminal = data.flags.isCriminal;
        result.flags.isTaxRisk = data.flags.isTaxRisk;
        result.flags.isFraud = data.flags.isFraud;
        return result;
    }
    private static readPersonWatchHitEntry(data: any): PersonWatchHitEntry {
        let result = new PersonWatchHitEntry();
        result.objectId = data.objectId || null;
        result.objectType = data.objectType || null;
        return result;
    }
    private static readFaceInfo(data: any): MediaFaceInfo {
        let result = new MediaFaceInfo();
        result.faceCount = data.faceCount || null;
        return result;
    }
    private static readIncidentInfo(data: any): PersonIncidentInfo {
        let incidentInfo = new PersonIncidentInfo();
        incidentInfo.recordCount = data.recordCount;
        incidentInfo.uniquePersonCount = data.uniquePersonCount;
        return incidentInfo;
    }
    private static readIncidentRange(data: any): PersonIncidentRange {
        let result = new PersonIncidentRange();
        result.min = new Date(data.minDateTime);
        result.max = new Date(data.maxDateTime);
        return result;
    }
    private static readRecord(data: any): Record {
        let result = new Record();
        result.recordId = data.recordId || null;
        result.authorId = data.authorId || null;
        result.importId = data.importId || null;
        result.loss = data.loss || null;
        result.humanId = data.humanId || null;
        result.dateTime = !data.dateTime ? null : new Date(data.dateTime + "Z");
        result.timestamp = !data.timestamp ? null : new Date(data.timestamp + "Z");
        result.location = Api.readLocation(data.location);
        result.status = data.status || null;
        result.comment = data.comment || null;
        result.photos = data.photos || null;
        result.files = data.files || null;
        result.flags = Api.readFlags(data.flags);
        for (var i = 0; i < data.persons.length; i++) {
            let item = data.persons[i];
            if (typeof item === "string") {
                result.persons.push(item);
            } else {
                result.persons.push(item.personId);
            }
        }
        for (let index = 0; index < data.companies.length; index++) {
            const element = data.companies[index];
            result.companies.push(element);
        }
        return result;
    }
    private static readFileDescriptor(data: any): FileDescriptor {
        return new FileDescriptor(data.fileId, data.userId, data.filename);
    }
    private static readUserLogDay(data: any): UserLogDate {
        const result = new UserLogDate();
        result.year = data.year;
        result.month = data.month;
        result.day = data.day;
        return result;
    }
    private static readPerson(data: any): RecordPerson {
        const result = new RecordPerson();
        result.personId = data.personId || null;
        result.importId = data.importId || null;
        result.authorId = data.authorId || null;
        result.humanId = data.humanId || null;
        result.location = Api.readLocation(data.location);
        result.fable = data.fable || null;
        result.comment = data.comment || null;
        result.status = parseInt(data.status);
        result.loss = data.loss || null;
        result.contacts = Api.readContacts(data.contacts);
        result.fields = Api.readFields(data.fields);
        result.flags = Api.readFlags(data.flags);
        result.photos = data.photos || null;
        result.files = data.files || null;
        if (typeof data.dateTime === "string") {
            result.dateTime = new Date(data.dateTime);
        }
        if (typeof data.timestamp === "string") {
            result.timestamp = new Date(data.timestamp);
        }
        return result;
    }
    private static readLocation(data: any): Location {
        let result = new Location();
        result.lat = data.lat || null;
        result.lng = data.lng || null;
        result.geoHash = data.geoHash || null;
        return result;
    }
    private static readFields(data: any): RecordFields {
        const result = new RecordFields();
        result.firstname = data.firstname || null;
        result.lastname = data.lastname || null;
        result.patronymic = data.patronymic || null;
        result.passportSerial = data.passportSerial || null;
        result.passportNumber = data.passportNumber || null;
        result.passportIssueDay = data.passportIssueDay || null;
        result.passportIssueMonth = data.passportIssueMonth || null;
        result.passportIssueYear = data.passportIssueYear || null;
        result.birthdayDay = data.birthdayDay || null;
        result.birthdayMonth = data.birthdayMonth || null;
        result.birthdayYear = data.birthdayYear || null;
        result.inn = data.inn;
        return result;
    }
    private static readFlags(data: any): RecordFlags {
        const result = new RecordFlags();
        result.isFraud = data.isFraud || null;
        result.isBankFraud = data.isBankFraud || null;
        result.isMicrofinanceFraud = data.isMicrofinanceFraud || null;
        result.isInsuranceFraud = data.isInsuranceFraud || null;
        result.isMoneyLaundering = data.isMoneyLaundering || null;
        result.isCargoThief = data.isCargoThief || null;
        result.isShopThief = data.isShopThief || null;
        result.isEmployRisk = data.isEmployRisk || null;
        result.isRealData = data.isRealData || null;
        result.isCompromised = data.isCompromised || null;
        return result;
    }
    private readPhone(data: any): Phone | null {
        if (data === null) {
            return null;
        }
        const countryCode = parseInt(data.countryCode);
        const nationalNumber = parseInt(data.nationalNumber);
        const result = new Phone(countryCode, nationalNumber);
        return result;
    }
    private readEmail(data: any): Email | null {
        if (data === null) {
            return null;
        }
        const result = new Email(data.recipient, data.hostname);
        return result;
    }
    private static readAutogeneratedUser(data: any): AutogeneratedUser {
        const result = new AutogeneratedUser();
        result.password = data.password;
        result.user = Api.readUser(data.user);
        return result;
    }
    private static readUserLogBatch(data: any): UserLogBatch {
        const result = new UserLogBatch();
        result.records = data.records.map(_ => Api.readUserLogRecord(_));
        result.session = data.session || null;
        result.total = data.total || null;
        return result;
    }
    private static readUserLogRecord(data: any): UserLogRecord {
        const result = new UserLogRecord();
        result.code = data.code || null;
        result.context = data.context || null;
        result.timestamp = data.timestamp || null;
        return result;
    }
    private static readContacts(data: any): RecordContacts {
        const result = new RecordContacts();
        result.emails = data.emails || null;
        result.phones = data.phones || null;
        return result;
    }
    private readFaceCompareResult(data: any): FaceCompareResult {
        const result = new FaceCompareResult();
        result.isTheSame = data.isTheSame;
        result.similarity = data.similarity;
        result.strictLevel = data.strictLevel;
        return result;
    }
    private readPhotoBound(data: any): PhotoBound {
        const result = new PhotoBound();
        result.x1 = data.x1;
        result.y1 = data.y1;
        result.x2 = data.x2;
        result.y2 = data.y2;
        result.rotate = data.rotate || "zero";
        result.faceId = data.faceId || null;
        return result;
    }
    private static readSimilarSearchResult(data: any): SimilarSearchResult {
        const result = new SimilarSearchResult();
        result.text = Api.readFeed(data.text);
        result.face = {};
        result.counts = data.count;
        Object.keys(data.face).forEach(_ => {
            result.face[_] = Api.readFeed(data.face[_]);
        });
        return result;
    }
    private static readUser(data: any): User {
        const result = new User(data.id, data.humanId);
        result.name = data.name;
        result.login = data.login;
        result.roles = data.roles;
        result.balance = data.balance;
        result.isBlocked = data.isBlocked;
        result.isSingleLogin = data.isSingleLogin;
        result.expire = data.expire;
        result.period = data.period;
        result.mode = data.mode;
        return result;
    }
    private static readMember(data: any): Member {
        return new Member(data.id, data.humanId);
    }
    private static readPhoneBookResult(data: any): Array<string> {
        let result = new Array<string>();
        for (let name of data.names) {
            result.push(name);
        }
        return result;
    }
    private static readBalanceRefillResponse(data: any): UserBalanceRefillResponse {
        const result = new UserBalanceRefillResponse();
        result.redirectUri = data.redirectUri || null;
        return result;
    }
    private static readPrice(data: Price): Price {
        return new Price(data.code, data.price);
    }
    private static readThrottleConfiguration(data: ThrottleConfiguration): ThrottleConfiguration {
        return new ThrottleConfiguration(data.window, data.windowSize, data.requestsLeft);
    }
    private static readUserPersonAcl(data: any): UserPersonAcl {
        let result = new UserPersonAcl();
        result.flagAcl = !!data.flagAcl ? Api.readUserPersonFlagAcl(data.flagAcl) : null;
        result.userAcl = !!data.userAcl ? Api.readUserPersonUserAcl(data.userAcl) : null;
        result.tagsAcl = !!data.tagsAcl ? Api.readUserPersonTagsAcl(data.tagsAcl) : null;
        return result;
    }
    private static readRecognizedDocument(data: any): RecognizedDocument {
        let result = new RecognizedDocument();
        result.type = data.type || null;
        result.fields = data.fields || null;
        return result;
    }
    private static readInfoPerson(data: any): InfoPerson {
        let result = new InfoPerson();
        result.firstname = data.firstname;
        result.lastname = data.lastname;
        result.patronymic = data.patronymic;
        result.birthdayDay = data.birthdayDay;
        result.birthdayMonth = data.birthdayMonth;
        result.birthdayYear = data.birthdayYear;
        result.address = data.address;
        result.work = data.work;
        result.phones = data.phones.map(_ => _.value);
        result.emails = data.emails.map(_ => _.value);
        return result;
    }
    private static readResolvedLocation(data: any): ResolvedLocation {
        let result = new ResolvedLocation();
        result.lat = data.lat || null;
        result.lng = data.lng || null;
        result.countryCode = data.countryCode || null;
        result.region = data.region || null;
        result.state = data.state || null;
        result.city = data.city || null;
        result.street = data.street || null;
        result.building = data.building || null;
        result.address = data.address || null;
        return result;
    }
    private static readUserPersonFlagAcl(data: any): UserPersonFlagAclRecord {
        let result = new UserPersonFlagAclRecord();
        result.flags = Api.readFlags(data.flags);
        return result;
    }
    private static readUserPersonUserAcl(data: any): UserPersonUserAclRecord {
        let result = new UserPersonUserAclRecord();
        result.userIds = data.userIds;
        return result;
    }
    private static readUserPersonTagsAcl(data: any): UserPersonTagsAclRecord {
        let result = new UserPersonTagsAclRecord();
        result.tags = data.tags;
        return result;
    }
    public static buildQueryString(params: any): string {
        const paramArray: any = [];
        Object.keys(params).forEach(_ => {
            let value = params[_];
            if (value === null) {
                return;
            }
            if (value === undefined) {
                return;
            }
            if (value instanceof Date) {
                value = value.toISOString();
            }
            let k = encodeURIComponent(_);
            let v = encodeURIComponent(value);
            paramArray.push(`${k}=${v}`);
        });
        return paramArray.join("&");
    }
    public static async convertBlobToBase64(blob: Blob): Promise<string> {
        let convert = (file: Blob) =>
            new Promise<string>((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result as any);
                reader.onerror = error => reject(error);
            });
        const prefix = ";base64,";
        const base64Url: string = await convert(blob);
        const index = base64Url.indexOf(prefix);
        return base64Url.substring(index + prefix.length);
    }
    public static resolveFetchResource(endpoint: string, baseAddress: string | null): string {
        if (baseAddress === null) {
            return "/api/" + endpoint.replace(/^\/+/, "");
        }
        let url = new URL(endpoint, baseAddress);
        return url.href;
    }
    public static resolveBaseAddress(): string | null {
        let baseAddress: string | null = null;
        let hostname = document.location.hostname;
        if (hostname === "127.0.0.1" || hostname === "localhost") {
            baseAddress = "https://api.stop-fraud.pro";
        }
        return baseAddress;
    }
}
export class FaceMatchLevelInfo {
    low: number | null = null;
    moderate: number | null = null;
}
export const Copyright = "© ООО «Би Ай Ти». ИАС «Стоп-Фрод»";
export const SearchTypes = {
    face: "Поиск по СРЛ",
    text: "Полнотекстовой запрос"
};
export class Core {
    public api: Api;
    public auth: any;
    public faceMatchLevelInfo: FaceMatchLevelInfo | null = null;
    public isInLegalMode: boolean | null = null;
    public impersonatedFrom: string | null = null;
    public accessLevel: number | null = null;
    public hasAnyNotification: boolean | null = null;
    public userLogCodes: any = null;
    constructor(secret: string, baseAddress: string | null = null) {
        this.api = new Api(secret, baseAddress);
    }
    public async start() {
        await this.updateAuth();
        this.lifecycle();
    }
    public raiseError(error: any, message: string) {
        console.log(error, message);
    }
    public computeFaceMatchLevel(score: number) {
        if (score <= this.faceMatchLevelInfo.low) {
            return 0;
        }
        if (score < this.faceMatchLevelInfo.moderate) {
            return 1;
        }
        return 2;
    }
    private lifecycle() {
        setTimeout(async () => {
            await this.updateAuth();
            this.lifecycle();
        }, 30 * 1000);
    }
    private async updateAuth(): Promise<void> {
        this.auth = await this.api.getAuth();
        this.faceMatchLevelInfo = new FaceMatchLevelInfo();
        this.faceMatchLevelInfo.low = this.auth.config.face.score.low;
        this.faceMatchLevelInfo.moderate = this.auth.config.face.score.moderate;
        this.impersonatedFrom = this.auth.impersonatedFrom || null;
        let roles: Array<string> = this.auth.user.roles;
        this.accessLevel = 100;
        this.userLogCodes = this.auth.config.user.log.actions;
        this.accessLevel = Core.resolveAccessLevel(roles);
        this.isInLegalMode = roles.indexOf("legal_mode") !== -1;
        this.hasAnyNotification = this.auth.notification.hasAny || false;
    }
    public async loadPhotoSafely(request: LoadPhotoRequest) {
        let resolvedCall;
        if (request instanceof LoadRecordPhotoRequest) {
            let recordRequest = request as LoadRecordPhotoRequest;
            resolvedCall = async () =>
                await this.api.getRecordPhoto(recordRequest.recordId, request.photoId, request.searchId);
        } else if (request instanceof LoadPersonPhotoRequest) {
            let personRequest = request as LoadPersonPhotoRequest;
            resolvedCall = async () =>
                await this.api.getPersonPhoto(personRequest.personId, request.photoId, request.searchId);
        } else {
            throw new Error("Failed to resolve photo request type");
        }
        while (true) {
            var base64 = undefined;
            try {
                base64 = await resolvedCall();
            } catch (error) {
                if (error.status === 451) {
                    base64 = hiddenPhotoStub;
                } else if (error.status === 503) {
                    await new Promise(_ => setTimeout(_, 5000));
                    continue;
                } else if (error.status === 204) {
                    base64 = null;
                } else {
                    this.raiseError(error, "Ошибка загрузки изображения");
                }
            }
            return base64 !== undefined ? base64 : null;
        }
    }
    public async createPersonContextFromRecordId(
        recordId: string,
        searchId: string | null = null
    ): Promise<PersonContext> {
        let result = Core.createPersonContext(searchId);
        let record = await this.api.getRecord(recordId, searchId);
        if (record.location !== null) {
            await this.loadLocationToPersonContext(result, record.location);
        }
        let connections = [];
        for (let personId of record.persons) {
            await this.addPersonToPersonContext(result, personId, searchId);
            connections.push(personId);
        }
        await this.loadRecordFiles(result, record);
        result.records.set(record.recordId, record);
        result.connections.set(record.recordId, connections);
        return result;
    }
    public async createPersonFeedFromPersonId(personId: string, searchId: string): Promise<FeedBatch> {
        let context = await this.createPersonContextFromPersonId(personId, searchId);
        let result = new FeedBatch();
        let item = new PersonFeedRecord();
        item.personId = personId;
        item.personContext = context;
        result.searchId = searchId;
        result.personContext = context;
        result.records.push(item);
        return result;
    }
    public async createCompanyFeedFromCompanyId(companyId: string, searchId: string | null = null): Promise<FeedBatch> {
        let context = await this.createPersonContextFromCompanyId(companyId, searchId);
        let result = new FeedBatch();
        let item = new CompanyFeedRecord(companyId, context);
        result.personContext = context;
        result.records.push(item);
        return result;
    }
    public async createPersonContextFromPersonId(
        personId: string,
        searchId: string | null = null
    ): Promise<PersonContext> {
        let result = Core.createPersonContext(searchId);
        await this.addPersonToPersonContext(result, personId, searchId);
        return result;
    }
    public async createPersonContextFromCompanyId(companyId: string, searchId: string | null): Promise<PersonContext> {
        let result = Core.createPersonContext(searchId);
        await this.addCompanyToPersonContext(result, companyId, searchId);
        return result;
    }
    public getCanEditPerson(person: RecordPerson): boolean {
        let adminIndex = this.auth.user.roles.indexOf("admin");
        if (adminIndex !== -1) {
            return true;
        }
        let editorIndex = this.auth.user.roles.indexOf("editor");
        if (editorIndex !== -1) {
            return true;
        }
        if (this.auth.user.id !== person.authorId) {
            return false;
        }
        if (person.status === 1) {
            return true;
        }
        let localEditorIndex = this.auth.user.roles.indexOf("editor_local");
        if (localEditorIndex !== -1) {
            return true;
        }
        return false;
    }
    private async addCompanyToPersonContext(
        context: PersonContext,
        companyId: string,
        searchId: string | null = null
    ): Promise<void> {
        let company = await this.api.getCompany(companyId, searchId);
        await this.loadCompanyAttributes(context, company);
        await this.loadCompanyIncidentInfo(context, company);
        context.companies.set(companyId, company);
    }
    private async addPersonToPersonContext(
        context: PersonContext,
        personId: string,
        searchId: string | null = null
    ): Promise<void> {
        try {
            let person = await this.api.getPerson(personId, searchId);
            let isOnWatch = await this.api.getIsPersonOnWatch(personId, searchId);
            if (isOnWatch) {
                context.watchedPersons.push(personId);
            }
            await this.loadPersonLocation(context, person);
            await this.loadPersonFiles(context, person);
            await this.loadPersonUsers(context, person);
            await this.loadPersonCounters(context, person);
            await this.loadPersonAvatars(context, person);
            await this.loadIncidentInfo(context, person);
            context.persons.set(person.personId, person);
        } catch (error) {
            if (error.status === 403) {
                context.hiddenPersons.push(personId);
            } else {
                throw error;
            }
        }
    }
    private async loadCompanyIncidentInfo(context: PersonContext, company: Company): Promise<void> {
        let incidentInfo = await this.api.getCompanyIncidentInfo(company.companyId, context.searchId);
        context.companyIncidentInfo.set(company.companyId, incidentInfo);
    }
    private async loadIncidentInfo(context: PersonContext, person: RecordPerson): Promise<void> {
        let incidentInfo = await this.api.getPersonIncidentInfo(person.personId, context.searchId);
        context.incidentInfo.set(person.personId, incidentInfo);
    }
    private async loadPersonCounters(context: PersonContext, person: RecordPerson): Promise<void> {
        let counters = await this.api.getPersonCounters(person.personId, context.searchId);
        Object.keys(counters).forEach(_ => {
            if (_ !== "BINDING") {
                return;
            }
            context.bindingCount.set(person.personId, counters[_]);
        });
    }
    private async loadPersonAvatars(context: PersonContext, person: RecordPerson): Promise<void> {
        try {
            let mediaId = await this.api.getPersonAvatar(person.personId, context.searchId);
            if (mediaId) {
                context.avatars.set(person.personId, mediaId);
            }
        } catch (error) {
            if (error.status === 404) {
                return;
            }
            if (error.status === 204) {
                return;
            }
            throw error;
        }
    }
    private async loadPersonUsers(context: PersonContext, person: RecordPerson): Promise<void> {
        let author = await this.api.getPersonAuthor(person.personId, context.searchId);
        context.users.set(person.authorId, author);
    }
    private async loadPersonLocation(context: PersonContext, person: RecordPerson): Promise<void> {
        if (person.location === null) {
            return;
        }
        await this.loadLocationToPersonContext(context, person.location);
    }
    private async loadLocationToPersonContext(context: PersonContext, location: Location): Promise<void> {
        if (location.lat === null || location.lng === null) {
            return;
        }
        let resolved = await this.api.resolveCoordinatesIntoLocation(location.lat, location.lng);
        if (resolved !== null) {
            context.resolvedLocations.set(resolved.geoHash, resolved.location);
        }
    }
    private async loadPersonFiles(context: PersonContext, person: RecordPerson): Promise<void> {
        for (let i = 0; i < person.files.length; i++) {
            let fileId = person.files[i];
            let descriptor = await this.api.getPersonFileDescriptor(person.personId, fileId, context.searchId);
            context.files.set(fileId, descriptor);
        }
    }
    private async loadCompanyAttributes(context: PersonContext, company: Company): Promise<void> {
        let attributes = await this.api.getCompanyAttributes(company.companyId, context.searchId);
        context.companyAttributes.set(company.companyId, attributes);
    }
    private async loadRecordFiles(context: PersonContext, record: Record): Promise<void> {
        for (let i = 0; i < record.files.length; i++) {
            let fileId = record.files[i];
            let descriptor = await this.api.getRecordFileDescriptor(record.recordId, fileId, context.searchId);
            context.files.set(fileId, descriptor);
        }
    }
    private static createPersonContext(searchId: string | null = null): PersonContext {
        let result = new PersonContext();
        result.searchId = searchId;
        return result;
    }
    public static resolveAccessLevel(roles: Array<string>): number {
        let adminIndex = roles.indexOf("admin");
        if (adminIndex !== -1) {
            return 0;
        }
        let managerIndex = roles.indexOf("manager");
        if (managerIndex !== -1) {
            return 1;
        }
        return 100;
    }
    public async updatePersonFiles(context: RecordPersonContext): Promise<void> {
        const personId = context.person.personId;
        const attachPhoto = async (file: RecordPhotoContext) => {
            file.id = await this.api.attachFile(personId, file.content, file.name);
        };
        const deletePhoto = async (file: RecordPhotoContext) => {
            return this.api.removeFileFromPerson(personId, file.id);
        };
        const insertPromises = context.files.filter(_ => !_.id).map(_ => attachPhoto(_));
        const removePromises = context.files.filter(_ => _.isDeleted && !!_.id).map(_ => deletePhoto(_));
        const promises = insertPromises.concat(removePromises);
        await Promise.all(promises);
    }
    public async updatePersonPhotos(context: RecordPersonContext): Promise<void> {
        const personId = context.person.personId;
        const attachPhoto = async (file: RecordPhotoContext) => {
            file.id = await this.api.attachFile(personId, file.content, file.name);
        };
        const deletePhoto = async (file: RecordPhotoContext) => {
            return this.api.removePhotoFromPerson(personId, file.id);
        };
        const insertPromises = context.photos.filter(_ => !_.id).map(_ => attachPhoto(_));
        const removePromises = context.photos.filter(_ => _.isDeleted && !!_.id).map(_ => deletePhoto(_));
        const promises = insertPromises.concat(removePromises);
        await Promise.all(promises);
    }
    public async updateRecordPhotos(context: RequestContext): Promise<void> {
        let recordId = context.record.recordId;
        const attachPhoto = async (file: RecordPhotoContext) => {
            file.id = await this.api.attachRecordFile(recordId, file.content, file.name);
        };
        const deletePhoto = async (file: RecordPhotoContext) => {
            return this.api.removePhotoFromRecord(recordId, file.id);
        };
        const insertPromises = context.photos.filter(_ => !_.id).map(_ => attachPhoto(_));
        const removePromises = context.photos.filter(_ => _.isDeleted && !!_.id).map(_ => deletePhoto(_));
        const promises = insertPromises.concat(removePromises);
        await Promise.all(promises);
    }
    public async updateRecordFiles(context: RequestContext): Promise<void> {
        const recordId = context.record.recordId;
        const attachPhoto = async (file: RecordPhotoContext) => {
            file.id = await this.api.attachRecordFile(recordId, file.content, file.name);
        };
        const deletePhoto = async (file: RecordPhotoContext) => {
            return this.api.removeFileFromRecord(recordId, file.id);
        };
        const insertPromises = context.files.filter(_ => !_.id).map(_ => attachPhoto(_));
        const removePromises = context.files.filter(_ => _.isDeleted && !!_.id).map(_ => deletePhoto(_));
        const promises = insertPromises.concat(removePromises);
        await Promise.all(promises);
    }
    public async commitRecordData(context: RequestContext, recordId: string) {
        try {
            for (let i = 0; i < context.persons.length; i++) {
                let person = context.persons[i];
                this.commitPersonData(person);
            }

            for (let i = 0; i < context.dropped.length; i++) {
                let person = context.dropped[i];
                await this.api.removePersonFromRecord(recordId, person.person.personId);
            }
            await this.api.setRecordComment(recordId, context.record.comment);
            await this.api.setRecordDateTime(recordId, context.record.dateTime);
            await this.api.setRecordLocation(recordId, context.record.location);
            await this.api.setRecordFlags(recordId, context.record.flags);
            await this.api.setRecordLoss(recordId, context.record.loss);
            await this.updateRecordPhotos(context);
            await this.updateRecordFiles(context);
            context.moveNext();
        } catch (error) {
            context.isInFailure = true;
        } finally {
            context.isInProcess = false;
        }
    }
    public async commitPersonData(person: RecordPersonContext) {
        try {
            if (person.isHidden) {
                return true;
            }
            let canEdit = this.getCanEditPerson(person.person);
            if (canEdit !== true) {
                return true;
            }
            let personId = person.person.personId;
            await this.api.setPersonComment(personId, person.person.comment);
            await this.api.setPersonFields(personId, person.person.fields);
            await this.api.setPersonDateTime(personId, person.person.dateTime);
            await this.api.setPersonContacts(personId, person.person.contacts);
            await this.api.setPersonFlags(personId, person.person.flags);
            await this.api.setPersonFable(personId, person.person.fable);
            await this.updatePersonPhotos(person);
            await this.updatePersonFiles(person);
            await this.api.commitPerson(personId);
            person.person.photos = [];
            let photos = [];
            for (let k = 0; k < person.photos.length; k++) {
                let photo = person.photos[k];
                if (!!photo.id && !photo.isDeleted) {
                    person.person.photos.push(photo.id);
                    photos.push(photo);
                }
            }
            person.photos = photos;
        } catch (error) {
            console.log(error);
        }
    }
    public async createCompanyFromIncident(recordId: string): Promise<Company> {
        const company = await this.api.createCompany();
        await this.api.connectCompanyWithIncident(recordId, company);
        return await this.api.getCompany(company);
    }
    public async setCompanyData(company: Company): Promise<void> {
        await this.api.setCompanyFlags(company.companyId, company.flags);
        await this.api.setCompanyINN(company.companyId, company.inn);
    }
}
